import React, { useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import setLanguage from 'next-translate/setLanguage'
import Modal from './core/Modal'
import useTranslation from 'next-translate/useTranslation'
import { changeUserLanguage } from '../lib/user'
import { useCommonContext } from '../context/common.context'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type CountryIconProps = {
  countryCode: string
  onClick?: () => void
}

const CountryIcon = ({ countryCode, onClick = null }: CountryIconProps) => {
  return (
    <ReactCountryFlag
      svg
      alt={'Flag_' + countryCode}
      className="flex rounded-full object-cover"
      style={{ height: '28px', width: '28px' }}
      countryCode={countryCode}
      onClick={onClick}
    />
  )
}

const LanguageSelector = ({ compact = false }): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const commonContext = useCommonContext()
  const { t, lang } = useTranslation('common')

  const languages = [
    {
      name: 'Deutsch',
      languageCode: 'de',
      regionCode: 'at',
      shortSign: 'de',
    },
    {
      name: 'English',
      languageCode: 'en',
      regionCode: 'gb',
      shortSign: 'en',
    },
    {
      name: 'Português',
      languageCode: 'pt',
      regionCode: 'br',
      shortSign: 'br',
    },
  ]

  const currentLanguage = languages?.find((l) => l.shortSign === lang)

  const changeLanguage = async (shortSign: string) => {
    if (shortSign != lang) {
      const language = commonContext.getLanguageByShortSign(shortSign)
      if (language != null) {
        await changeUserLanguage(language.id, shortSign)
        await setLanguage(shortSign)
      }
    }
    setShowModal(false)
  }

  return (
    <>
      {currentLanguage && (
        <div
          className="language-selector flex items-center relative h-9 cursor-pointer"
          onClick={() => setShowModal(true)}
        >
          <div className="language-selector-inner flex space-x-2 items-center h-full pl-1 pr-8 bg-gray-500 bg-opacity-20 hover:bg-opacity-30 rounded-full">
            <CountryIcon countryCode={currentLanguage.regionCode} />
            {!compact && (
              <>
                <span className="language-selector-inner-lang-shortsign text-xs font-semibold uppercase md:hidden">
                  {currentLanguage.shortSign}
                </span>
                <span className="language-selector-inner-lang-name text-xs font-semibold hidden md:flex">
                  {currentLanguage.name}
                </span>
              </>
            )}
          </div>
          <FontAwesomeIcon
            icon={faChevronDown}
            className="language-selector-indicator absolute right-3 opacity-60 text-gray-500 pointer-events-none"
          />
        </div>
      )}

      <Modal title={t('LanguageSelector.title')} show={showModal} closable onClose={() => setShowModal(false)}>
        {languages?.map((lang) => {
          return (
            <div className="language-selector-modal-option mb-4 last:mb-0 cursor-pointer" key={lang.name}>
              <a onClick={() => changeLanguage(lang.shortSign)}>
                <CountryIcon countryCode={lang.regionCode} />
                <span className="language-selector-modal-option-name ml-4">{lang.name}</span>
              </a>
            </div>
          )
        })}
      </Modal>
    </>
  )
}

export default LanguageSelector
